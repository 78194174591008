<template>
    <!-- /test/app-table/customers -->
    <div class="page-test-app-table-customers container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="customers-table"

                    title="Customers"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    :clickable="true"
                    :selected-row="selected_row"
                    :selected-key="selected_key"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :route="route"

                    ref="table"

                    @row-click="onRowClick"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            cols: [
                { key: 'name',       title: 'Name',         highlight: true, sort: { field: 'Name'      },                                   },
                { key: 'email',      title: 'Email',                                                                       class: 'special', },
                { key: 'mobile',     title: 'Phone number', highlight: true,                                                                 },
                { key: 'status',     title: 'Status',                        sort: { field: 'Status'    }, type: 'status',                   },
                { key: 'created-at', title: 'Registration',                  sort: { field: 'CreatedAt' },                                   },
            ],
            rows: [],

            loading: false,

            selected_row: null,
            selected_key: 'id',

            /**
             * filter.sort.field, sort[n].field and cols[n].sort.field should be named exactly like on the backend
             */
            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc',
                },
            },
            sort: [
                { field: 'Name',      title: 'Name',         },
                { field: 'Status',    title: 'Status',       },
                { field: 'CreatedAt', title: 'Registration', },
            ],

            route: 'customers',
        }
    },

    created() {
        this.init()
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutsideTable)
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutsideTable)
    },

    methods: {
        init() {
            this.getCustomers()
        },

        getCustomers() {
            const delay = 1000

            this.loading = true

            setTimeout(() => {
                let id = 1

                this.rows = [
                    {
                        id: id++,
                        name: 'Lesley Sanders',
                        email: 'lesleysanders@momentia.com',
                        mobile: '+1 (804) 444-3370',
                        status: {
                            status: 'success', // _table-tag-colors.scss
                            text: 'Active',
                        },
                        'created-at': '2014-09-20 03:27:49',
                    },
                    {
                        id: id++,
                        name: 'Lesley Sanders',
                        email: 'lesleysanders@momentia.com',
                        mobile: '+1 (804) 444-3370',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                        'created-at': '2014-09-20 03:27:49',
                    },
                    {
                        id: id++,
                        name: 'Lesley Sanders',
                        email: 'lesleysanders@momentia.com',
                        mobile: '+1 (804) 444-3370',
                        status: {
                            status: 'pending', // _table-tag-colors.scss
                            text: 'Pending',
                        },
                        'created-at': '2014-09-20 03:27:49',
                    },
                    {
                        id: id++,
                        name: 'Lesley Sanders',
                        email: 'lesleysanders@momentia.com',
                        mobile: '+1 (804) 444-3370',
                        status: {
                            status: 'inprogress', // _table-tag-colors.scss
                            text: 'InProgress',
                        },
                        'created-at': '2014-09-20 03:27:49',
                    },
                    {
                        id: id++,
                        name: 'Lesley Sanders',
                        email: 'lesleysanders@momentia.com',
                        mobile: '+1 (804) 444-3370',
                        status: {
                            status: 'held', // _table-tag-colors.scss
                            text: 'Held',
                        },
                        'created-at': '2014-09-20 03:27:49',
                    },
                ]

                this.loading = false
            }, delay)
        },

        onRowClick(row) {
            this.selected_row = row[this.selected_key]
        },

        handleClickOutsideTable(event) {
            if (!this.$refs.table.$el.contains(event.target)) {
                this.selected_row = null
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getCustomers()
        },
    },
}
</script>

<style lang="scss">
.page-test-app-table-customers {
    .customers-table {
        margin: 48px 0;

        @include table-cols-width((275px, 275px, 275px, 275px, 275px), true);

        .rows {
            .row {
                .col {
                    &.special {
                        .text {
                            color: var(--color-table-primary);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
.page-test-app-table-customers {
    .customers-table {
        @include table-cols-width-mobile((100px, 145px, 24px), true);
    }
}
}
</style>